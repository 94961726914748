<template>
  <div class="home">

    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <!-- <div v-if="!$auth.loading"> -->
      <!-- show login when not authenticated -->
      <!-- <button v-if="!$auth.isAuthenticated" @click="login">Log in</button> -->
      <!-- show logout when authenticated -->
      <!-- <button v-if="$auth.isAuthenticated" @click="logout">Log out</button> -->
    <!-- </div> -->

  <br/>

  <div>
    <b-img :src="require('@/images/iot-theme-person-using-laptop-white-table-182302182.jpg')" fluid></b-img> 
  </div>


    <HelloI18n/>

    <p>aaaa</p>

    <p>{{ $t('main-screen.hello') }}</p>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HelloI18n from '@/components/HelloI18n.vue'



export default {
  name: 'home',
  components: {
    // HelloWorld,
    HelloI18n
  }
  // ,
  //  methods: {
  //   // Log the user in
  //   login() {
  //     this.$auth.loginWithRedirect();
  //   },
  //   // Log the user out
  //   logout() {
  //     this.$auth.logout({
  //       returnTo: window.location.origin
  //     });
  //   }
  // }
}
</script>
