<template>
  <div>
    <div>
      <img :src="$auth.user.picture">
      <h2>{{ $auth.user.name }}</h2>
      <p>{{ $auth.user.email }}</p>
    </div>

    <div>
      <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
      <button @click="apiCall">api Call</button>
      <button @click="resendEmailVerification">resend Email Verification</button>
    </div>

    <div>
      <pre>{{ response }}</pre>
    </div>
  </div>
</template>

<script>

import axiosService from '@/services/axiosService.js'

export default {
  data: function() {
    return {
      response: 'waiting...'
    }
  },
  methods: {

    apiCall: async function() {

      //const token = await this.$auth.getTokenSilently();

      //console.log(token);
      //axios.defaults.headers.common['Authorization'] = "Bearer" + token
      //sessionStorage.setItem('token', JSON.stringify(token))


      axiosService.getCall(this.$auth,'v1/Devices')
       .then(response => {
          this.response = response.data
          //localStorage.setItem('token', JSON.stringify(token)) 
          console.log(response.data) // For now, logs out the response
        })
        .catch(error => {
          this.response = 'There was an error: ' + error.response
          console.log('There was an error: ', error.response) // Logs out the error
        })      

      // const token = await this.$auth.getTokenSilently();

      // axios
      //   .get(
      //     'https://localhost:5001/api/devices/',
      //     { headers: {'Authorization': "bearer " + token}}
      //   )   // Does a get request
      //   .then(response => {
      //     this.response = response.data
      //     localStorage.setItem('token', JSON.stringify(token)) 
      //     //console.log(response.data) // For now, logs out the response
      //   })
      //   .catch(error => {
      //     this.response = error.response
      //     //console.log('There was an error:', error.response) // Logs out the error
      //   })      
    },
    resendEmailVerification: async function () {
      axiosService.postCall(this.$auth)
    }
  }
}
  


</script>