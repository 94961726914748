<template>
  <!-- <div class="deviceinfo"> -->

    


    <b-container >

      <b-row class="text-center">
        <b-col>
            <ToolBarPage @clickRefresh="DeviceInfo(0)"/>
        </b-col>

        <b-button-group>

          <b-dropdown variant="light" size="sm" class="mb-0" :text="viewText">
            <b-dropdown-item v-on:click="methodThatForcesUpdate(1)">Grid View</b-dropdown-item>
            <b-dropdown-item v-on:click="methodThatForcesUpdate(0)">Cards View</b-dropdown-item>
            <b-dropdown-item v-on:click="methodThatForcesUpdate(2)">Graph View</b-dropdown-item>
          </b-dropdown>

        </b-button-group>    


      </b-row>

      <!-- <b-row class="text-right">

      </b-row>       -->

      <b-row v-if="loading==true">
          <b-col class="text-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
          </b-col>
      </b-row>    

      <b-row v-if="loading==false">
        <b-col class="text-center">
          <b>{{ deviceName }} mode: {{ deviceMode }}</b>
            <div>
            <b-button @click="DeviceConfiguration" v-b-modal.modal-1>config</b-button>
            <b-modal 
              id="modal-1"
              @cancel="handleCancel"
              @ok="handleOk">
                  <form>
                      <br>
                        <input type="text" placeholder="Name" v-model="deviceName">
                      <br>
                      <br>
                        <input type="text" placeholder="Period" v-model="deviceConfiguration.period">
                      <br>  
                      <br> 
                        <b-form-select v-model="deviceConfiguration.period" :options="optionsPeriod" ></b-form-select>  
                      <br>                                    
                    <!-- <div>
                      <br>
                        <input type="text" placeholder="Name" v-model="user.name">
                      <br>
                        <input type="text" placeholder="Email" v-model="user.email">
                      <br>
                        <input type="text" placeholder="Password" v-model="user.password">
                      <br>
                    </div> -->
                    <!-- <div>
                      <b-btn @click="modalShow = false">Cancel</b-btn>
                      <b-btn variant="outline-primary" @click="addUser">Create</b-btn>
                    </div> -->
                  </form>
            </b-modal>

            </div>
        </b-col>
      </b-row>

      
      <!--  -->
      <b-row class="text-center">

        <b-button pill variant="outline-primary" v-on:click="DeviceInfo(0)">24H</b-button>
        <b-button pill variant="outline-primary" v-on:click="DeviceInfo(1)">1W</b-button>
        <b-button pill variant="outline-primary" v-on:click="DeviceInfo(2)">1M</b-button>


        <b-button  pill variant="outline-primary" v-on:click="DeviceAlertConfiguration" v-b-modal.modal-2>
          <b-icon icon="arrow-up" aria-hidden="true"></b-icon> Settings
            <b-modal 
              id="modal-2"
              @cancel="handleCancel"
              @ok="handleOkAlertConfiguration">

              <ejs-grid :dataSource="deviceAlertConfiguration.alertMeasure" :editSettings="editSettings" :toolbar="toolbar" :actionComplete="actionComplete">
                  <e-columns>
                    <e-column field='id' headerText='id' :isPrimaryKey='true' :visible='false'></e-column>
                    <e-column field='measure.type' headerText='Type'></e-column>
                    <e-column field='comparision'  headerText='Comp'></e-column>
                    <e-column field='measure.value' headerText='Value'></e-column>
                    <e-column field='measure.scale' headerText='Scale'></e-column>
                  </e-columns>

              </ejs-grid>
            </b-modal>    

        </b-button>

      </b-row>


      <b-row v-if="loading==false && viewType==1">
        <b-col>
          
          <ejs-grid  :dataSource="Measurements" :allowPaging="true" :pageSettings='pageSettings'  :allowSorting='true' >
              
              <e-columns>
                <e-column v-for="(Column,index) in Columns" :key="index"
                  :field="Column"
                  textAlign='Left'>
                </e-column>
              </e-columns>

              <!-- <e-columns>
                <e-column field='OrderID' headerText='Order ID' textAlign='Right' width=90></e-column>
                <e-column field='CustomerID' headerText='Customer ID' width=120></e-column>
                <e-column field='Freight' headerText='Freight' textAlign='Right' format='C2' width=90></e-column>
              </e-columns> -->
          </ejs-grid>
        </b-col>
      </b-row>

      <div v-if="loading==false && viewType==2">
        <b-row v-for="(Column,index) in ColumnsGraph" :key="index">
          <b-col v-if="isMobile()==false">
          <ejs-chart :title='Column.title' :primaryXAxis='Column.primaryXAxis' :primaryYAxis='Column.primaryYAxis' :crosshair='Column.crosshair' :tooltip='Column.tooltip'>
              <e-series-collection>
                  <!-- <e-series v-for="(Column,index) in ColumnsGraph" :key="index" 
                    :dataSource='MeasurementsGraph' type='Line' xName='date' :yName='Column' name='Sales'> </e-series> -->
                  <e-series :dataSource='MeasurementsGraph' type='Line' xName='date' :yName='Column.name' :name='Column.name' > </e-series>
              </e-series-collection>
          </ejs-chart>
          </b-col>
          <b-col v-if="isMobile()==true">
          <ejs-chart :title='Column.title' :primaryXAxis='Column.primaryXAxis' :primaryYAxis='Column.primaryYAxis' >
              <e-series-collection>
                  <!-- <e-series v-for="(Column,index) in ColumnsGraph" :key="index" 
                    :dataSource='MeasurementsGraph' type='Line' xName='date' :yName='Column' name='Sales'> </e-series> -->
                  <e-series :dataSource='MeasurementsGraph' type='Line' xName='date' :yName='Column.name' :name='Column.name'> </e-series>
              </e-series-collection>
          </ejs-chart>
          </b-col>

        </b-row>
      </div>

    <!-- </b-container> -->

      <!-- <b-container > -->


        <!-- <b-card-group deck> -->
      <b-row v-if="loading==false && viewType==0">
      <b-col>
        <b-card class="my-2"
          v-for="(Measurement,index) in response.measurements" :key="index"
          border-variant="secondary"
          header="Secondary"
          header-border-variant="secondary"
          align="center"
        >        
          <template v-slot:header>
            <h6 class="mb-0">
              {{ Measurement.FetchDate }}
            </h6>
            <small class="text-muted">{{Measurement.lastUpdate}}</small>
          </template>                  
          
          <b-card-text>
            <!-- ds {{ Measurement.doorStatus }}
            vi {{ Measurement.vibration }}
            ma {{ Measurement.magnet }}
            ev {{ Measurement.eventCount }}     
            fw {{ Measurement.firmware }}        -->
            <div v-for="(Value,index) in Measurement.values" :key="index">
                  {{Value.type }} 
                  {{Value.value}}
                  {{Value.scale}}              
            </div> 

            <div v-if="Measurement.gps">
              <b-link v-b-tooltip.hover v-bind:title="['latitude ', Measurement.gps.latitude, ' longitude ', Measurement.gps.longitude]">gps</b-link>
            </div>

          </b-card-text>

          <!-- <template v-slot:footer>
            <small class="text-muted">{{Measurement.lastUpdate}}</small>
          </template> -->

        </b-card>

      </b-col>

      </b-row>
        

          
        <!-- </b-card-group> -->


      </b-container>
      
  <!-- </div> -->
</template>


<script>
import axiosService from '@/services/axiosService.js';

import { Page, Sort, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";

import ToolBarPage from '@/components/ToolBarPage.vue';

import { LineSeries, Category, DateTime, Crosshair, Tooltip } from '@syncfusion/ej2-vue-charts';



// import { BIconArrowUp } from 'bootstrap-vue'


 export default {
    name: 'deviceInfo',
    components: {
        //DeviceInfoC
        ToolBarPage 
        // BIconArrowUp
    },
    props: {
      deviceid: String,
      deviceMode: Number
    },        
    data: function () {
        return {
          response : [],
          // data : [],
          Measurements : [],
          MeasurementsGraph : [],
          Columns : [],
          ColumnsGraph : [],
          viewType: 0,
          deviceName: "",
          loading: Boolean,
          pageSettings: { pageSize: 50 },
          deviceConfiguration : {},
          deviceAlertConfiguration : {
            alertMeasure : [],
            configurationType : []
          },
          editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
          isEdit: false,
          toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
          optionsPeriod: [
            { value: '0', text: '10 min' },
            { value: '1', text: '1 hour' },
            { value: '2', text: '6 hours' },
            { value: '3', text: '24 hours' },
          ],
          seriesData:[
                      { x: new Date(2020, 7, 27, 11, 10, 0), y: 10, z: 15 }, { x: new Date(2020, 7, 27, 11, 20, 0), y: 30, z: 15 },
                      { x: new Date(2020, 7, 27, 11, 30, 0), y: 25, z: 23 }, { x: new Date(2020, 7, 27, 11, 40, 0), y: 20, z: 24 }
              ]              
        }
    },
    provide: {  
    grid: [Page, Sort, Edit, Toolbar ],
    chart: [LineSeries, Category, DateTime, Crosshair, Tooltip]
    },        
    methods:{

        DeviceInfo: function (period) {

            var o =  Object();
            var ograph = Object();
            this.loading = true;
            var sintervalType = 'Days';
            var iinterval = '1';
            var sdateFormat = 'd-M-y';

            var ographtemp = Object();            
            var iseqNumberActual = 0; //
            var iseqNumberAnterior = 0; //

            var deviceDataActual = '';


            switch (period) {
              case 0 : //24H
                sintervalType = 'Hours';
                iinterval = 1;
                sdateFormat = 'HH:mm';
                break;
              case 1 : //1W
                sintervalType = 'Days';
                iinterval = 1;
                sdateFormat = 'd/M/yy HH:mm';
                break;      
              case 2 : //1M
                sintervalType = 'Days';
                iinterval = 5;
                sdateFormat = 'd/M/yy HH:mm';
                break;          
                    
            }


            axiosService.getCall(this.$auth, '/v1/Devices/' + this.deviceid + '/' + this.deviceMode + '/' + period )
            .then(response => {
                
                this.response = response.data;

                this.deviceName = response.data.name;

                this.Measurements=[];
                this.MeasurementsGraph=[];
                
                this.Columns=[];
                //this.Columns.push('FetchDate');
                this.Columns.push('LastUpdate');

                this.ColumnsGraph=[];

                

                response.data.measurements.forEach((value) =>  {

                  o = new Object();
                  ograph = new Object();

                  value.FetchDate = new Date(value.time*1000).toLocaleString();

                  value.values.forEach((value2) => {


                    o[value2.type] = value2.value + " " + value2.scale;

                    // Afegim la columna només si no existeix previament
                    if (this.Columns.indexOf(value2.type) === -1) {
                      this.Columns.push(value2.type);
                      if (this.isMobile()==true) {
                        this.ColumnsGraph.push(
                          { name: value2.type, 
                            title: value2.type,
                            // crosshair: {  enable: true, lineType: 'Vertical' },
                            // tooltip: { enable: true, shared: true, format: '${series.name} :  ${point.y}' },
                            // marker: { visible: true },                          
                            primaryXAxis: { valueType: 'DateTime', intervalType: sintervalType, interval: iinterval, labelFormat: sdateFormat, title: '' },
                            primaryYAxis: { title: value2.type }
                          }
                        );
                      } else {
                        this.ColumnsGraph.push(
                          { name: value2.type, 
                            title: value2.type,
                            crosshair: {  enable: true, lineType: 'Vertical' },
                            tooltip: { enable: true, shared: true, header: '${point.x}', format: '${series.name} :  ${point.y}' },
                            marker: { visible: true },                          
                            primaryXAxis: { valueType: 'DateTime', intervalType: sintervalType, interval: iinterval, labelFormat: sdateFormat, crosshairTooltip: { enable: false, fill: 'green' }, title: '' },
                            primaryYAxis: { title: value2.type }
                          }
                        );
                      }


                    }

                    ograph[value2.type] = value2.value

                  })

                  o.LastUpdate = value.lastUpdate;

                  o.time = value.time;
                  o.seqNumber = value.seqNumber;
                  o.gps = value.gps;

                  ograph.time = value.time;
                  ograph.seqNumber = value.seqNumber;
                  ograph.date = new Date(value.time*1000);

                  this.Measurements.push(o)
                  
                  this.MeasurementsGraph.push(ograph)
                  
                  
                  //***** si hi ha un salt en el número de seqüència, afegim un registre sense valor perquè surti en blanc a la gràfica */ 
                  if (iseqNumberActual == 0) { 
                      iseqNumberAnterior = value.seqNumber;
                      iseqNumberActual = value.seqNumber;

                      deviceDataActual = value.data;

                      console.log(iseqNumberActual + ' ' + iseqNumberAnterior);
                  } else {
                      iseqNumberAnterior = iseqNumberActual;
                      iseqNumberActual = value.seqNumber;

                      deviceDataActual = value.data;

                      console.log(iseqNumberActual + ' ' + iseqNumberAnterior);
                  }

                  if ((iseqNumberAnterior - iseqNumberActual) > 1 && deviceDataActual.length == 8) {
                      
                      ographtemp = new Object();       
                
                      ographtemp.time = ograph.time + 1;
                      ographtemp.date = new Date(ographtemp.time*1000); 
                      this.MeasurementsGraph.push(ographtemp)
                  }
                  //********************************************************* */
                  
                })

                this.Measurements.sort((a,b) => (a.time < b.time) ? 1 : -1); //descending
                this.MeasurementsGraph.sort((a,b) => (a.time > b.time) ? 1 : -1); //ascending 

                this.loading = false;
                

                console.log(response.data) // For now, logs out the response
                })
                .catch(error => {
                  this.loading=false;
                //this.data = 'There was an error: ' + error.response
                console.log('There was an error: ', error.response) // Logs out the error
                })   
        },
        DeviceConfiguration: function() {
          axiosService.getCall(this.$auth, '/v1/Devices/' + this.deviceid )
            .then(response => {
                this.deviceConfiguration = response.data.sigFoxSensitV3ConfigPayload;

                console.log(response.data) // For now, logs out the response
            })
            .catch(error => {
              this.loading=false;
            //this.data = 'There was an error: ' + error.response
            console.log('There was an error: ', error.response) // Logs out the error
            })              
        },
        DeviceAlertConfiguration: function() {
          axiosService.getCall(this.$auth, '/v1/Devices/GetDeviceAlertConfiguration/' + this.deviceid )
            .then(response => {
                this.deviceAlertConfiguration.alertMeasure = response.data.alertMeasure;
                this.deviceAlertConfiguration.configurationType = response.data.configurationType;

                //afegim id a l'array per poder fer update del grid
                for (let i = 0; i < this.deviceAlertConfiguration.alertMeasure.length; i++) {
                  this.deviceAlertConfiguration.alertMeasure[i].id = i
                }
                

                console.log(response.data) // For now, logs out the response
            })
            .catch(error => {
              this.loading=false;
            //this.data = 'There was an error: ' + error.response
            console.log('There was an error: ', error.response) // Logs out the error
            })      
        },
        methodThatForcesUpdate(viewType) {
          // ...
          // switch(this.viewType) {

          // };
          if (this.viewType != viewType) {
            this.viewType = viewType;
            this.$forceUpdate();  // Notice we have to use a $ here
          }
          // ...
        },        
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        handleOk() {
            axiosService.putCall(this.$auth, '/v1/Devices/' + this.deviceid + '/config', this.deviceConfiguration)
            .then(response=> {
              console.log(response.data)
            })
        },
        handleOkAlertConfiguration(bvModalEvent) {
          if (this.isEdit) {
            bvModalEvent.preventDefault()
            alert("editing")
          } else {
            axiosService.putCall(this.$auth, '/v1/Devices/UpdateDeviceAlertConfiguration/' + this.deviceid + '/', this.deviceAlertConfiguration)
            .then(response=> {
              console.log(response.data)
            })
          }
        },        
        handleCancel() {

        },
        actionComplete(args) {
          
          switch (args.requestType) {
            case "beginEdit": 
              this.isEdit=true;
              break;
            case "save": 
              this.isEdit=false;
              break;
          }
          
        }           
    },        
    computed: {
      viewText: function () {
        switch(this.viewType) {
          case 0 : 
            return "Cards View";
          case 1 :
            return "Grid View";
          case 2 : 
            return "Graph View";
          default :
            return "Cards View";
        }
      }

      //   if (this.MobileView==true) {
      //     return "Cards View"
      //   } else {
      //     return "Grid View"
      //   }
      // }

    },
    created: async function() {

      this.DeviceInfo(0)
      if (this.isMobile()) {
        this.viewType = 0
      } else {
        this.viewType = 1
      }
      // this.MobileView=this.isMobile();
         
    }

    // ,
    // watch:{
    //   MobileView: function() {
    //     console.log(this.MobileView);
    //     this.methodThatForcesUpdate();
    //   }
    // }

  }


</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';  
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css";
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap.css";

       

</style>
