<template>

    <div class="mb-0">

        <b-button-toolbar key-nav aria-label="Toolbar with button groups">
            <b-button-group class="mx-1">
                <b-button variant="light" @click="goBack">
                    <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Back
                </b-button>         
                <b-button variant="light" @click="onClickRefresh">
                    <b-icon icon="arrow-counterclockwise" aria-hidden="true"></b-icon> Refresh
                </b-button>                  
                <!-- <b-button variant="light" @click="goBack">&lsaquo;</b-button>
                <b-button variant="light" @click="onClickRefresh">Refresh</b-button> -->
            </b-button-group>
        </b-button-toolbar>    

    </div>
    
</template>

<script>


//import { BIconArrowUp } from 'bootstrap-vue'

export default {
    components: {
        //BIcon,
        //BIconArrowUp,
        //BIconArrowDown
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
        onClickRefresh() {
            this.$emit('clickRefresh')            
        }
    }
}
</script>