import Vue from 'vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'

// Import the Auth0 configuration
//import { domain, clientId, audience } from "../auth_config.json";

var domain = process.env.VUE_APP_AUTH0_DOMAIN;
var clientId = process.env.VUE_APP_AUTH0_CLIENTID;
var audience = process.env.VUE_APP_AUTH0_AUDIENCE;


// Import the plugin here
import { Auth0Plugin } from "./auth";

import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
import i18n from './i18n';
import './registerServiceWorker';
import { ChartPlugin } from '@syncfusion/ej2-vue-charts';

// import firebaseMessaging from './firebase'
// Vue.prototype.$messaging = firebaseMessaging

import OneSignalVue from 'onesignal-vue'


// import VueI18n from 'vue-i18n';

// Install the authentication plugin here
Vue.use(
  Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
}
);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(GridPlugin);
Vue.use(DatePickerPlugin);
Vue.use(ComboBoxPlugin);
Vue.use(ChartPlugin);

Vue.use(OneSignalVue);


// Vue.use(VueI18n);

//Vue.config.productionTip = false


// const messages = {
//   en: {
//     message: {
//       hello: '{msg} world',
//       hello1: 'Hello world',
//     }
//   },
//   ca: {
//     message: {
//       hello: '{msg} mon',
//       hello1: 'Hola mon'
//     }
//   }
  
// }


// Create VueI18n instance with options
// const i18n = new VueI18n({
//   locale: 'ca', // set locale
//   messages, // set locale messages
// })


new Vue({
  router,
  i18n,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: '6b41fd3d-622d-4187-8dbe-37bac2b48904' });
  }
}).$mount('#app')
