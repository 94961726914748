<template>
  <div class="about">

  <!-- <b-jumbotron header="BootstrapVue" lead="Bootstrap v4 Components for Vue.js 2">
    <p>For more information visit website</p>
    <b-button variant="primary" href="#">More Info</b-button>
  </b-jumbotron> -->

  <h1>About Page</h1>

  <b-container fluid class="bv-example-row">
    <b-row class="mb-3">
      <b-col>
        <ejs-grid :dataSource="data" :allowPaging="true" :allowSorting='true' :pageSettings='pageSettings'>
            <e-columns>
              <e-column field='Name'></e-column>
              <e-column field='Price'></e-column>
            </e-columns>

            <!-- <e-columns>
              <e-column field='OrderID' headerText='Order ID' textAlign='Right' width=90></e-column>
              <e-column field='CustomerID' headerText='Customer ID' width=120></e-column>
              <e-column field='Freight' headerText='Freight' textAlign='Right' format='C2' width=90></e-column>
            </e-columns> -->
        </ejs-grid>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <ejs-datepicker :placeholder="waterMark"></ejs-datepicker>
      </b-col>
      <b-col>
        <ejs-datepicker :placeholder="waterMark"></ejs-datepicker>
      </b-col>
      <b-col>
        <ejs-datepicker :placeholder="waterMark"></ejs-datepicker>
      </b-col>
    </b-row>

  </b-container>




     <!-- <grid :data="gridData" :columns="gridColumns"></grid> -->





  </div>

</template>

<script>

//import Grid from '@/components/Grid.vue'
import axiosService from '@/services/axiosService.js'
import { Page, Sort } from "@syncfusion/ej2-vue-grids";

export default {
  //name: 'app',
    data() {
      return {
        gridData: [
          { name: 'American alligator', location: 'Southeast United States' },
          { name: 'Chinese alligator', location: 'Eastern China' },
          { name: 'Spectacled caiman', location: 'Central & South America' },
          { name: 'Broad-snouted caiman', location: 'South America' },
          { name: 'Jacaré caiman', location: 'South America' },
          { name: 'Black caiman', location: 'South America' },
        ],
        gridColumns: ['name', 'location'],
        data1: [
          { OrderID: 10248, CustomerID: 'VINET', Freight: 32.38 },
          { OrderID: 10249, CustomerID: 'TOMSP', Freight: 11.61 },
          { OrderID: 10250, CustomerID: 'HANAR', Freight: 65.83 },
          { OrderID: 10251, CustomerID: 'VICTE', Freight: 41.34 },
          { OrderID: 10252, CustomerID: 'SUPRD', Freight: 51.3 },
          { OrderID: 10253, CustomerID: 'HANAR', Freight: 58.17 },
          { OrderID: 10254, CustomerID: 'CHOPS', Freight: 22.98 },
          { OrderID: 10255, CustomerID: 'RICSU', Freight: 148.33 },
          { OrderID: 10256, CustomerID: 'WELLI', Freight: 13.97 }
        ],   
        data: [],
        pageSettings: { pageSize: 5 },
        waterMark:'select date',
        }
    },
    provide: {  
    grid: [Page, Sort]
    },
    created: async function() {

      //const token = await this.$auth.getTokenSilently();

      //sessionStorage.setItem('token', JSON.stringify(token))

      axiosService.getCall(this.$auth, 'v1/devices')
       .then(response => {
          this.data = response.data
          //localStorage.setItem('token', JSON.stringify(token)) 
          console.log(response.data) // For now, logs out the response
        })
        .catch(error => {
          //this.data = 'There was an error: ' + error.response
          console.log('There was an error: ', error.response) // Logs out the error
        })      

    }
    
    
    //,
  // components: {
  //   Grid
  // }
}
</script>

<style>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';  
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css";
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap.css";
</style>