<template>
    <b-navbar toggleable="lg" type="dark" variant="primary">
    <!-- <b-navbar type="dark" variant="dark"> -->

      <b-navbar-brand to="/">IoT Online</b-navbar-brand>


      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>


        <b-navbar-nav>
          <!-- <b-nav-item v-if="$auth.isAuthenticated" to="/About">About</b-nav-item> -->
          <b-nav-item v-if="$auth.isAuthenticated" to="/profile">Profile</b-nav-item> 
          <b-nav-item v-if="$auth.isAuthenticated" to="/devices">Devices</b-nav-item>
        </b-navbar-nav>


        <b-navbar-nav class="ml-auto">


          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item v-for="(lang, i) in langs" 
              :key="`Lang${i}`" 
              :value="lang.value" 
              @click="$i18n.locale = lang.value ">
              {{ lang.text }}
              {{ lang.value==$i18n.locale ? '*' : '' }} 
            </b-dropdown-item>
          </b-nav-item-dropdown>   


          <!-- Check that the SDK client is not currently loading before accessing is methods -->
          <div v-if="!$auth.loading">
            <!-- show login when not authenticated -->
            <b-nav-item v-if="!$auth.isAuthenticated" @click="login">Log in</b-nav-item>
            <!-- show logout when authenticated -->
            <b-nav-item v-if="$auth.isAuthenticated" @click="logout">Log out {{ $auth.user.name }}</b-nav-item>  
          </div>      
        

          <!-- Navbar dropdowns -->
          <!-- <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</b-dropdown-item>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>-->     

          <!-- <b-nav-item-dropdown text="User" right>
            <b-dropdown-item href="#">Account</b-dropdown-item>
            <b-dropdown-item href="#">Settings</b-dropdown-item>
          </b-nav-item-dropdown> -->

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link v-if="$auth.isAuthenticated" to="/profile">Profile</router-link>
    </div> -->    
</template>

<script>

export default {
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  name: 'locale-changer',
  data () {
    return { 
      langs: [
        { value: 'ca', text: 'català' },
        { value: 'es', text: 'castellano' },
        { value: 'en', text: 'english' },
      ]
      }
  },
  watch: {
      
  }
}
</script>

<style scoped>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>