import qs from 'qs'
import axios from 'axios'



const apiClient = axios.create({
    //baseURL: `http://localhost:5000/api`,
    //baseURL: `https://iot-online-docker.azurewebsites.net/api`,
    baseURL: `` + process.env.VUE_APP_AXIOS_BASE_URL + ``,
    withCredentials: false, // This is the default
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'//,
      //'Authorization': 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqZEdSRUk1TUVOR01USkZOVGsxTmtORU9ESkRNemsyUmtZelJFTTBNVVZGUWtVNU16STBRZyJ9.eyJpc3MiOiJodHRwczovL2Rldi1ucjhyN2Nyby5ldS5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NWUwNzVmNjE2ZTNkMTQwZWIwYWFhNDhiIiwiYXVkIjpbImh0dHBzOi8vZ2xvc3NhcnkuY29tIiwiaHR0cHM6Ly9kZXYtbnI4cjdjcm8uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTU3ODc4MTU2NCwiZXhwIjoxNTc4ODY3OTY0LCJhenAiOiJzWXVvdU8xYjc1NEtwN2E1YzJadlVEMVZmM25TZFVTYSIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.c4YX4TYoD0QZanaRF5YIPtBjyo3DJYCx4WPg0COePfJHBjcrqcav4_8solBvp_PfpIrnf-8niIrrqbxnKEmF8VrpcqC8Q3pJAtn01w1fKXZhI-QhzrjntozAI6BEItBvPFdceuksPQUmNverHM0UHlFYAO8ccO0N_5viCfk7BBJDkMR6F_pAGapVGv0Y3gmxew1S1d2b91RyOPI9b5uqm38LtmrWbq2gdhk3BpzikgqrW0d_sIA3zoi-v-d4lc9EqExTSyiWZS6Hg-mzyZ-eq_ouzSIZi8TNGj1cC23F-Kuykni6bHAVtMoTUOuukOZfTwcqrzsnYMSxc56MfkJpTQ'
    }
  });


  // const apiClientAuth0 = axios.create({
  //   baseurl: 'https://dev-nr8r7cro.eu.auth0.com',
  //   withCredentials: false, 
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  //   form: {
  //     'grant_type': 'client_credentials',
  //     'client_id': 'aPbCc1A58CTL3DCPME0qJxNXoAMlCL3U',
  //     'client_secret': '9xyx2_wl8fXlixYLDWNzFGtC067c5UiLbK7SQgS-RABP8FTxOWe9K2dYHj6tspqA',
  //     'audience': 'https://dev-nr8r7cro.eu.auth0.com/api/v2/'      
  //   }
  // });


  // axios.interceptors.request.use((config) => {
  //   // const user = v.$root.user;
  //   // if (user) {
  //   //   const authToken = user.access_token;
  //   //   if (authToken) {
  //   //     config.headers.Authorization = `Bearer ${authToken}`;
  //   //   }
  //   // }
  //   const token = localStorage.getItem('token');
  //   console.log(token);
  //   config.headers.Authorization = `Bearer ${token}`;
  //   return config;
  // },
  // (err) => {
  //   //What do you want to do when a call fails?
  //   console.log(err)
  // });

export default {

    // getDevices: async function($auth) {

    //   const token = await $auth.getTokenSilently();
    //   //console.log(token)

    //   apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    //   return apiClient.get('/devices')
    // },

    // getUserOrganizations: async function($auth) {
 
    //   const token = await $auth.getTokenSilently();

    //   apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    //   return apiClient.get('/userOrganizations')      
    // },

    getCall: async function ($auth, apiroute) {

      const token = await $auth.getTokenSilently();

      //const token = await $auth.getTokenWithPopup();

      //console.log(token);

      console.log(apiClient.baseURL + 'baseURL');

      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return apiClient.get(apiroute)         

    },

    putCall: async function ($auth, apiroute, data) {

      const token = await $auth.getTokenSilently();

      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return apiClient.put(apiroute, data)    

    },

    postCallAuth0: async function ($auth) {


      // const formData = new FormData();
      // formData.append('grant_type', 'client_credentials');
      // formData.append('client_id', 'aPbCc1A58CTL3DCPME0qJxNXoAMlCL3U');
      // formData.append('client_secret', '9xyx2_wl8fXlixYLDWNzFGtC067c5UiLbK7SQgS-RABP8FTxOWe9K2dYHj6tspqA');
      // formData.append('audience', 'https');

      console.log($auth.domain)

      axios({
        'method': 'POST',
        'url': 'https://dev-nr8r7cro.eu.auth0.com/oauth/token',
        data: qs.stringify({
          'grant_type': 'client_credentials',
          'client_id': '' + process.env.VUE_APP_AUTH0_ClientIdApiExplorerApplication + '',
          'client_secret': '' + process.env.VUE_APP_AUTH0_ClientSecretApiExplorerApplication + '',
          'audience': '' + process.env.VUE_APP_AUTH0_AudienceApiExplorerApplication + ''
        }),           
        'headers': {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        console.log(response.data.access_token);
        console.log($auth.user.sub);
        axios({
          'method': 'POST',
          'url': 'https://dev-nr8r7cro.eu.auth0.com/api/v2/jobs/verification-email',
          data: qs.stringify({
            'user_id': $auth.user.sub,
            'client_id': '' + process.env.VUE_APP_AUTH0_ClientIdApiExplorerApplication + '',
          }),           
          'headers': {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + response.data.access_token
          }
        })
         
      })
      .catch(error => {
         console.log(error.response) 
      })


    }



}