<template>
  <div id="app">

    <NavBar/>

    <keep-alive include="deviceList">

      <router-view/>

    </keep-alive>

  </div>
</template>


<script>

import NavBar from '@/components/NavBar.vue'

// import { provide } from 'vue'
// import firebaseMessaging from './firebase'

// import OneSignalVue from 'onesignal-vue';


export default {
  name:'HelloWorld',
  props: {
    msg: String
  },  
  components: {
    NavBar
  },
  // provide: {
  //   // messaging: firebaseMessaging
  // },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
  },  
  
  // mounted() {
  //   if (Notification.permission === 'granted') {
  //     console.log('Notificaciones push habilitadas');
  //   } else {
  //     Notification.requestPermission().then(permission => {
  //       if (permission === 'granted') {
  //         console.log('Notificaciones push habilitadas');
  //       }
  //     });
  //   }

  //   this.$messaging.getToken({ vapidKey: "BMx5yIBuzIn_1gekuqcTEnEBCt-AiAMLxB_6Ip5_SMixxBC6DgOiOm4KN-aS8XnfIgicLwCHbdwy1WTXtDqynMQ" })
  //   .then(token => console.log('Token de registro:', token))
  //   .catch(error => console.log('Error al enviar la notificación push:', error));
    
  // },
  //composition api
  // setup() {
  //   provide('messaging', firebaseMessaging)

  // }
}



</script>


<style>

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
