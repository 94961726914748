<template>


  <b-container>

    <!-- <b-row>   
    
      <b-col cols="12" sm="4" class="my-1"
        :key="index"
        v-for="(item, index) in items" 
          >
        <b-card         
          :bg-variant="item.variant"
          text-variant="white"
          :header="item.title"
          class="text-center"
          >
          <p class="card-text">
            {{item.body}}
          </p>
        </b-card>  

      </b-col>

    </b-row>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
    </b-row>  -->
  


    <!-- <div> -->

        <!-- <div>
            <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
                <b-dropdown class="mx-1" right :text="selected_organization.OrganizationName" >
                    <b-dropdown-item v-for="(organization,index) in organizations" :key="organization.Id" v-on:click="DeviceList(index)">
                        {{organization.OrganizationName}}
                    </b-dropdown-item>
                </b-dropdown>
            </b-button-toolbar>
        </div> -->



            <!-- <b-card-group deck> -->

                <!-- <Device v-for="(device) in devices" :key="device.Id"
                    :Id="device.Id"
                    :Name="device.Name"
                    :Measurements="device.Measurements"
                >
                </Device> -->
                

            <!-- </b-card-group> -->

        <b-row>
            <b-col>
                <ToolBar @clickRefresh="DeviceList(0)"/>
            </b-col>
        </b-row>


        <b-row v-if="loading==true">
            
            <b-col class="text-center">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
            </b-col>

        </b-row>       


        <b-row>
        
            <b-col cols="12" sm="4" class="my-2"
                v-for="(device) in devices" :key="device.Id"
            >

            <!-- <b-card-group deck> -->

                <b-card 
                    border-variant="secondary"
                    header="Secondary"
                    header-border-variant="secondary"
                    align="center"
                >

                    <template v-slot:header>
                        <router-link :to="{ name: 'deviceinfo', params: { deviceid: device.Id, deviceMode: device.measurements[0].mode  } }">{{device.name}}</router-link>
                    </template>

                    <div v-for="(Measurement,index) in device.measurements" :key="index">

                        <b-card-text >
                        
                            <!-- ds {{ Measurement.doorStatus }}
                            vi {{ Measurement.vibration }}
                            ma {{ Measurement.magnet }}
                            ev {{ Measurement.eventCount }}
                            fw {{ Measurement.firmware }} -->

                            <div v-for="(Value,index) in Measurement.values" :key="index">
                                {{Value.type }}
                                {{Value.value}}
                                {{Value.scale}}
                                
                            </div>

                            mode: {{ Measurement.mode }}

                        </b-card-text>

                    </div>

                    <template v-slot:footer>
                        <div v-for="(Measurement,index) in device.measurements" :key="index">
                        <small class="text-muted">{{Measurement.lastUpdate}}  ({{Measurement.batteryLevel}}%)  </small>
                        </div>                      
                    </template>              

                </b-card>

            <!-- </b-card-group> -->

            </b-col>    

        </b-row>

      <b-row>
          <b-col>

            <!-- <b-pagination
                v-model="currentPage"
                :total-rows="15"
                :per-page="5"
                first-number
            ></b-pagination>         -->
  
            <!-- <button class="push"  @click="displayNotification">Push</button> -->
  
          </b-col>
      </b-row>        


    </b-container>

    <!-- </div> -->
</template>

<script>
import axiosService from '@/services/axiosService.js'

import ToolBar from '@/components/ToolBarPage.vue'

// import Device from '@/components/Device.vue'


// const items = [
//   {
//     title: "Primary",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "primary"
//   },
//   {
//     title: "Secondary",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "secondary"
//   },
//   {
//     title: "Success",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "success"
//   },
//   {
//     title: "Info",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "info"
//   },
//   {
//     title: "Warning",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "warning"
//   },
//   {
//     title: "Danger",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     variant: "danger"
//   }
// ];



export default {
    name: 'deviceList',
     components: {
         //Device,
         ToolBar
    },
    data: function () {
        return {
            organizations: [],
            selected_organization: String,
            devices: [],
            loading: false
            //,items: items
        }
    },
    methods:{
        DeviceList: function (index) {
            this.selected_organization = this.organizations[index]

            //console.log('/v1/Devices?Organization=' + this.organizations[index].Id)

            this.loading=true;

            axiosService.getCall(this.$auth, '/v1/Devices' )
            .then(response => {
                this.devices = response.data
                this.loading=false;

                console.log(response.data) // For now, logs out the response
                })
                .catch(error => {
                    this.loading=false
                //this.data = 'There was an error: ' + error.response
                console.log('There was an error: ', error.response) // Logs out the error
                })   
        },
        displayNotification () {
            Notification.requestPermission(function(status) {
                console.log('Notification permission status:', status);
            });
            if (Notification.permission === 'granted') {
                navigator.serviceWorker.getRegistration()
                .then(function (reg) {
                    if(reg == undefined){
                    console.log("only works online")
                    return
                    }
                    var options = {
                    body: 'First notification!',
                    icon: './static/img/notification-flat.png',
                    vibrate: [100, 50, 100],
                    data: {
                        dateOfArrival: Date.now(),
                        primaryKey: 1
                    },
                    actions: [
                        {
                        action: 'explore',
                        title: 'Go to the site',
                        icon: './static/img/checkmark.png'
                        },
                        {
                        action: 'close',
                        title: 'Close the notification',
                        icon: './static/img/xmark.png'
                        }
                    ]
                    }
                    reg.showNotification('Your Message Here!', options)
                }
                )
            }
        }        
    },        
    created: async function() {

        console.log("created");
        //console.log(this.organizations[0]);
        console.log("email_verified: " + this.$auth.user.email_verified)

      this.loading=true;

      axiosService.getCall(this.$auth, '/v1/Organizations')
       .then(response => {
          this.organizations = response.data
          this.selected_organization = this.organizations[0]

          this.DeviceList(0)

          console.log(response.data) // For now, logs out the response
        })
        .catch(error => {
            this.loading=false;
          //this.data = 'There was an error: ' + error.response
          console.log('There was an error: ', error.response) // Logs out the error
        })             
    },
    mounted: function() {

      //console.log(this.organizations[0]);
      console.log("mounted");
    
    },
    destroyed: function() {
        console.log("destroyed");
    }

}
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap.css";
</style>